import { Component, ErrorInfo } from "react"


export default class ErrorBoundary extends Component<{ fallback: any }> {
    state = { hasError: false }

    static getDerivedStateFromError(error: Error) {
        return { hasError: true }
    }

    componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
        console.log(error, errorInfo)
    }

    render () {
        if (this.state.hasError) {
            return this.props.fallback
        }

        return this.props.children
    }
}