import { Suspense, lazy, useState, useEffect, Fragment, FC } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import ConfigProvider from '../providers/Config/Provider'
import { CacheProvider } from '@emotion/react'
import createCache, { EmotionCache } from '@emotion/cache'
import WebSettingsProvider, { useWebSettings } from '../providers/WebSettings'
import AdminService from '../services/AdminService'
import ErrorBoundary from '../services/ErrorBoundary'
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js"
import { reactPlugin, createAppInsight } from "../services/AppInsights"
import { IWebSettingsContext } from '../providers/WebSettings/types'
import { IConfig } from '../../app/config/config'


const Layout = lazy(() => import('./Layout'))

export const getNonceValue = () => {
    return (document.querySelector("body script[src*='/index']") as HTMLElement)?.nonce || "empty"
}

const App: FC<{ config: IConfig }> = ({config: appConfig}: any) => {
    const config = {...appConfig}
    const { pages, components, containers } = config
    const { LandingPage = false } = pages || {}
    const { Loading = () => <div /> } = components || {}
    const { AppContainer = Fragment } = containers || {}
    const { webSettings = {}, setWebSettings } = useWebSettings()

    const cache: EmotionCache = createCache({
        key: 'my-prefix-key',
        nonce: getNonceValue(),
      });

    const [appInsightKey, setAppInsightKey] = useState<string | undefined>(undefined)

    useEffect(() => {
        if (appInsightKey) {
            const appInsights = createAppInsight(appInsightKey)
            appInsights.loadAppInsights()
        }
    }, [appInsightKey])

    useEffect(() => {
        if (Object.keys(webSettings).length == 0) {
            if ((process.env.MAINTENANCE == "true") && setWebSettings) return setWebSettings({ active_languages: ["cs", "en", "sk"], default_app_language: "en" })

            AdminService.getWebSettings()
                .then((data: IWebSettingsContext["webSettings"]) => {
                    if (setWebSettings) setWebSettings(data)
                    setAppInsightKey(data?.app_insight_key || "")
                })
                .catch((e) => {
                    if (!e.response && (e.message == "Network Error") && setWebSettings) setWebSettings({ active_languages: ["cs", "en", "sk"], default_app_language: "en" })
                })
        }
    }, [])

    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <Suspense fallback={<Loading/>}>
                <CacheProvider value={cache}>
                    <ConfigProvider appConfig={config.config}>
                        <AppContainer>
                            <BrowserRouter>
                                <Routes>
                                    {LandingPage && (
                                        <Route path="/" element={<LandingPage />} />
                                    )}
                                    <Route
                                        path="*"
                                        element={
                                            <Suspense fallback={<Loading />}>
                                                {<Layout />}
                                            </Suspense>
                                        }
                                    />
                                </Routes>
                            </BrowserRouter>
                        </AppContainer>
                    </ConfigProvider>
                </CacheProvider>
            </Suspense>
        </AppInsightsContext.Provider>
    )
}

const AppComponent = (config: any) => {
    return (
        <ErrorBoundary fallback="An error occured">
            <WebSettingsProvider>
                <App config={config}  />
            </WebSettingsProvider>
        </ErrorBoundary>
    )
}

export default AppComponent