import Context from './Context'
import {JSXElementConstructor} from 'react'

const withContainer = (Component:JSXElementConstructor<any>) => {
    const ChildComponent = (props: any) => {
        return (
            <Context.Consumer>
                {(contextProps) => {
            return <Component {...contextProps} {...props} />
        }}
        </Context.Consumer>
    )
    }

    return ChildComponent
}

export default withContainer