import { IAuthUser } from "../providers/Auth/types"
import {
    IListResponse,
    IOrganization,
    IDepartment,
    IUser,
    IInvitation,
    IInvitedUser,
    IPassword,
    ITicket,
    IActionLog,
    ITimezonesList,
    IParams,
    ILanguagesChoices
} from "./types/admin"
import {
    removeUserEmptyFields,
    removeEmptyValuesForUser,
    removeEmptyFields,
    handleParams
} from './functions'
import {
    authorizedApiClient,
    unauthorizedApiClient,
    noCredentialApiClient,
    formDataApiClient
} from "./clients"
import { IFilterField } from "../../mui-base/containers/Page/types"
import { IWebSettingsContext } from "../providers/WebSettings/types"
import { parseDefaultDiagnoses } from "../../app/components/functions"


export type TToken = { key: string }

const getListOfOrganizations = async (token: string, userGroup: string, pageName: string, fields: IFilterField[] = [], params: IParams = {}): Promise<IListResponse<IOrganization>> => {
    if (!userGroup || !userGroup.includes("admin")) throw { response: { data: { detail: "messages.missing_permission" } } }

    params = handleParams(pageName, params, fields)
    params.ordering = "id"
    const response = await authorizedApiClient(token).get<IListResponse<IOrganization>>("/api/organizations/", { params: params })
    return response.data
}

const getMyOrganization = async (token: string): Promise<IOrganization> => {
    const response = await authorizedApiClient(token).get<IOrganization>("/api/organizations/get_my_organization/")
    return response.data    
}

const getDepartmentsOfOrganizations = async (token: string, userGroup: string): Promise<IListResponse<IOrganization> | IOrganization> => {
    if ((!userGroup) || (!userGroup.includes("admin"))) throw { response: { data: { detail: "messages.missing_permission" } } }

    const response = await authorizedApiClient(token).get<IListResponse<IOrganization>>(
        (userGroup == "admin")
        ? 
        "/api/departments/get_organization_departments/"
        :
        "/api/organizations/get_my_organization/"
    )
    return response.data    
}

const getUsersOfOrganizations = async (token: string, userGroup: string): Promise<{ size: number, results: IOrganization[] } | IOrganization> => {
    if ((!userGroup) || (!userGroup.includes("admin"))) throw { response: { data: { detail: "messages.missing_permission" } } }

    const response = await authorizedApiClient(token).get<{ size: number, results: IOrganization[] } | IOrganization>(
        (userGroup == "admin")
        ? 
        "/api/users/get_organization_users/"
        :
        "/api/users/get_my_organization/"
    )
    return response.data    
}

const getOrganizationById = async (token: string, id: number): Promise<IOrganization> => {
    const response = await authorizedApiClient(token).get<IOrganization>(`/api/organizations/${id}/`)

    if (response.data.default_diagnoses && Array.isArray(response.data.default_diagnoses)) response.data.default_diagnoses = response.data.default_diagnoses.join("\n")

    if (response.data.allowed_diagnoses && Array.isArray(response.data.allowed_diagnoses)) response.data.allowed_diagnoses = response.data.allowed_diagnoses.join("\n")

    return response.data
}

const createOrganization = async (token: string, id: any, data: IOrganization): Promise<IOrganization> => {
    var requestData = { ...data }
    requestData.default_diagnoses = parseDefaultDiagnoses(requestData.default_diagnoses)
    requestData.allowed_diagnoses = parseDefaultDiagnoses(requestData.allowed_diagnoses)

    const response = await authorizedApiClient(token).post<IOrganization>("/api/organizations/", { ...requestData })
    return response.data
}

const updateOrganization = async (token: string, id: number, data: IOrganization): Promise<IOrganization> => {
    var requestData = { ...data }
    delete requestData.name_key
    requestData.default_diagnoses = parseDefaultDiagnoses(requestData.default_diagnoses)
    requestData.allowed_diagnoses = parseDefaultDiagnoses(requestData.allowed_diagnoses)

    const response = await authorizedApiClient(token).put<IOrganization>(`/api/organizations/${id}/`, requestData)
    return response.data
}

const deleteOrganization = async (token: string, id: number): Promise<{}> => {
    const response = await authorizedApiClient(token).delete<{}>(`/api/organizations/${id}/`)
    return response.data
}

const getListOfDepartments = async (token: string, group: string, id: number, pageName: string, fields: IFilterField[] = [], params: IParams = {}): Promise<IListResponse<IDepartment>> => {
    if ((!group) || (!group.includes("admin")) || (id == 0)) throw { response: { data: { detail: "messages.missing_permission" } } }
    
    params = handleParams(pageName, params, fields)
    params.ordering = "id"
    var url = "/api/departments/get_my_organization_departments/"
    
    if (group == "admin") {
        url = "/api/departments/"
        params.organization = id
    }

    const response = await authorizedApiClient(token).get<IListResponse<IDepartment>>(url, { params: params })
    return response.data
}

const getDepartmentById = async (token: string, id: number): Promise<IDepartment> => {
    const response = await authorizedApiClient(token).get<IDepartment>(`/api/departments/${id}/`)
    return response.data
}

const createDepartment = async (token: string, id: any, requestData: IDepartment): Promise<IDepartment> => {
    var data = { ...requestData }
    const heatmaps = Boolean(requestData.heatmaps)
    const dr_grading = Boolean(requestData.dr_grading)

    if (data.organization) data.organization = parseInt(String(data.organization))
    data = removeEmptyFields(data)
    data.heatmaps = heatmaps
    data.dr_grading = dr_grading

    const response = await authorizedApiClient(token).post<IDepartment>("/api/departments/", data)
    return response.data
}

const updateDepartment = async (token: string, id: number, requestData: IDepartment): Promise<IDepartment> => {
    var data = { ...requestData }
    const heatmaps = Boolean(requestData.heatmaps)
    
    const dr_grading = Boolean(requestData.dr_grading)
    delete data.name_key

    data = removeEmptyFields(data)
    data.heatmaps = heatmaps
    data.dr_grading = dr_grading

    const response = await authorizedApiClient(token).put<IDepartment>(`/api/departments/${id}/`, data)
    return response.data
}

const deleteDepartment = async (token: string, id: number): Promise<{}> => {
    const response = await authorizedApiClient(token).delete<{}>(`/api/departments/${id}/`)
    return response.data
}

const getCurrentUser = async (token: string, id: number): Promise<IAuthUser> => {
    if (!token) throw { response: { data: { detail: "messages.client_error.authentication" } } }
    const response = await authorizedApiClient(token).get<IAuthUser>("/api/users/current_user/")
    return response.data
}

const saveAgreementWithManual = async (token: string): Promise<{}> => {
    if (!token) throw { response: { data: { detail: "messages.client_error.authentication" } } }
    const response = await authorizedApiClient(token).post<{}>("/api/users/accept_version/", {})
    return response.data
}

const getListOfUsers = async (token: string, pageName: string, fields: IFilterField[] = [], params: IParams = {}): Promise<IListResponse<IUser>> => {
    params = handleParams(pageName, params, fields)
    params.ordering = "id"
    const response = await authorizedApiClient(token).get<IListResponse<IUser>>("/api/users/", { params: params })
    return response.data
}

const getUserById = async (token: string, id: number): Promise<IUser> => {
    const response = await authorizedApiClient(token).get<IUser>(`/api/users/${id}/`)
    return response.data
}

const createUser = async (token: string, id: any, requestData: IUser): Promise<IUser> => {
    var data = { ...requestData }
    data.is_staff = (data.groups == "admin")
    data.organization_id = data.organization_id || data.organization
    data.department_id = data.department_id || data.department
    data.email = data.email.toLowerCase()

    delete data.organization
    delete data.department

    Object.entries(data).map(([key, value]) => {
        const k = key as keyof IUser
        if ((value === null) || (value == 0)) delete data[k]
    })

    const response = await authorizedApiClient(token).post<IUser>("/api/users/", data)
    return response.data
}

const createInvitedUser = async (key: string, id: any, requestData: IInvitedUser): Promise<IUser | Error> => {  
    console.log(requestData)
    var data = { ...requestData }
    if (data.confirm_password !== data.password) throw { response: { data: { detail: "messages.validation.confirm_password_error" } } }

    data.status = (typeof(data.status) == "string") ? parseInt(data.status) : data.status
    data.organization_id = data.organization_id || data.organization || 0
    data.department_id = data.department_id || data.department || 0

    if (data.organization_id == 0) data.organization_id = null
    if (data.department_id == 0) null

    data = removeUserEmptyFields<IInvitedUser>(data)

    if ((data.organization_id === null) || (data.department_id === null)) data.groups = "guest"
    data.is_staff = ((data.groups == "admin") || (data.groups[0] == "admin"))

    delete data.organization
    delete data.department
    delete data.confirm_password

    const response = await unauthorizedApiClient({"InviteKey": key}).post<IUser>("/api/users/create_invite_user/", data)
    return response.data
}

const updateUser = async (token: string, id: number, requestData: IUser): Promise<IUser> => {
    var data = { ...requestData }
    data.organization_id = data.organization_id || data.organization
    data.department_id = data.department_id || data.department

    delete data.organization
    delete data.department

    data = removeEmptyValuesForUser(data)
    
    const response = await authorizedApiClient(token).put<IUser>(`/api/users/${id}/`, data)
    return response.data
}

const updateMyProfileData = async (token: string, id: number, requestData: IUser): Promise<IUser> => {
    const userAuth = localStorage.getItem("auth")
    if (!userAuth || !JSON.parse(userAuth)?.user?.id) throw { response: { data: { detail: "messages.missing_permission" } } }

    var data = removeEmptyValuesForUser({ ...requestData })

    const response = await authorizedApiClient(token).put<IUser>(`/api/users/${JSON.parse(userAuth)?.user?.id}/`, data)
    return response.data
}

const deleteUser = async (token: string, id: number): Promise<{}> => {
    const response = await authorizedApiClient(token).delete<{}>(`/api/users/${id}/`)
    return response.data
}

const changeGroupOfUser = async (token: string, id: number, groupName: string): Promise<{}> => {  
    const response = await authorizedApiClient(token).post(`/api/users/${id}/set_group/`, { groups: groupName })
    return response.data
}

const getListOfInvitations = async (token: string, pageName: string, fields: IFilterField[] = [], params: IParams = {}): Promise<IListResponse<IInvitation>> => {
    params = handleParams(pageName, params, fields)
    params.status_min = 0
    params.status_max = 1
    params.ordering = "id"
    const response = await authorizedApiClient(token).get<IListResponse<IInvitation>>("/api/invitations/", { params: params })
    return response.data
}

const getInvitationById = async (token: string, id: number): Promise<IInvitation> => {
    const response = await authorizedApiClient(token).get<IInvitation>(`/api/invitations/${id}/`)
    return response.data
}

const getInvitationByKey = async (key: string): Promise<IInvitation> => {
    const response = await unauthorizedApiClient({"InviteKey": key}).get<IInvitation>("api/invitations/get_invite_by_key/")
    return response.data
}

const createInvitation = async (token: string, id: any, requestData: IInvitation): Promise<IInvitation> => {
    var data = removeUserEmptyFields<IInvitation>({ ...requestData })

    data.organization = (!data.organization || (data.organization == 0)) ? null :  data.organization
    data.department = (!data.department || (data.department == 0)) ? null :  data.department
    data.email = data.email.toLowerCase()

    if ((data.groups != "guest") && ((data.organization === null) || (data.department === null))) data.groups = "guest"

    const response = await authorizedApiClient(token).post<IInvitation>("/api/invitations/", data)
    return response.data
}

const updateInvitation = async (token: string, id: number, requestData: IInvitation): Promise<IInvitation> => {  
    var data = removeUserEmptyFields<IInvitation>({ ...requestData })

    data.organization = (!data.organization || (data.organization == 0)) ? null :  data.organization
    data.department = (!data.department || (data.department == 0)) ? null :  data.department
    
    if ((data.groups != "guest") && ((data.organization === null) || (data.department === null))) data.groups = "guest"

    const response = await authorizedApiClient(token).put<IInvitation>(`/api/invitations/${id}/`, data)
    return response.data
}

const deleteInvitation = async (token: string, id: number): Promise<{}> => {
    const response = await authorizedApiClient(token).delete<{}>(`/api/invitations/${id}/`)
    return response.data
}

const resendInvitation = async (token: string, id: number, data: any): Promise<{}> => {
    const response = await authorizedApiClient(token).post<{}>(`api/invitations/${id}/resend_email/`, {})
    return response.data
}

const changePassword = async (token: string, id: number, requestData: IPassword): Promise<{} | Error> => {  
    var data = { ...requestData }

    if (data.confirm_password !== data.new_password) throw { response: { data: { detail: "messages.validation.confirm_password_error" } } }
    delete data.confirm_password

    const response = await authorizedApiClient(token).post<{}>(`/api/users/${id}/change_password/`, data)
    return response.data
}

const resetPassword = async (token: string, id: number, requestData: { email?: string, password?: string, confirm_password?: string, password_token?: string, auth_token?: string }): Promise<{}> => {  
    var data = { ...requestData }
    
    if (data.confirm_password !== data.password) throw { response: { data: { detail: "messages.validation.confirm_password_error" } } }
    if (data.confirm_password) delete data.confirm_password

    data.email = data.email?.toLowerCase()

    const response = await noCredentialApiClient().post<{}>("/api/users/reset_password/", data)
    return response.data
}

const signIn = async (requestData: { email: string, password: string }): Promise<TToken> => {
    var data = { ...requestData }
    data.email = data.email.toLowerCase()

    const response = await noCredentialApiClient().post<{ key: string }>("/dj-rest-auth/login/", data)
    return response.data
}

const sendTicket = async (token: string, data: ITicket): Promise<{} | Error> => {  
    if (!token) throw { response: { data: { detail: "messages.client_error.authentication" } } }

    if (!data.message) throw { response: { data: { detail: "messages.validation.no_ticket_message" } } }

    var requestData = { ...data } as any

    if (data.attachments?.length != 0) {
        var ticketFormData: FormData = new FormData()
        ticketFormData.append("category", String(data.category_value))
        ticketFormData.append("message", data.message)
        
        for (const file of data.attachments as File[]) {
            ticketFormData.append(file.name, file, file.name)
        }

        requestData = ticketFormData
    }

    requestData.category = data.category_value
    delete requestData.category_value

    const response = await formDataApiClient(token).post<{}>("/api/ticket/create_ticket/", requestData)
    return response.data
}

const getListOfActionLogs = async (token: string, pageName: string, fields: IFilterField[] = [], params: IParams = {}): Promise<IListResponse<IActionLog>> => {
    params = handleParams(pageName, params, fields)
    const response = await authorizedApiClient(token).get<IListResponse<IActionLog>>("/api/actions/", { params: params })
    return response.data
}

const getActionLogById = async (token: string, id?: string | number): Promise<IActionLog> => {
    const response = await authorizedApiClient(token).get<IActionLog>(`/api/actions/${String(id)}/`)
    return response.data
}

const getWebSettings = async (): Promise<IWebSettingsContext["webSettings"]> => {
    const response = await noCredentialApiClient().get<IWebSettingsContext["webSettings"]>("/api/websettings/")
    return response.data
}

const getListOfTimezones = async (token: string, userGroup: string): Promise<ITimezonesList> => {
    if ((!userGroup) || (!userGroup.includes("admin"))) throw { response: { data: { detail: "messages.missing_permission" } } }
    const response = await authorizedApiClient(token).get<ITimezonesList>("/api/locations/timezones_choices/")
    return response.data
}

const getLanguagesChoices = async (): Promise<ILanguagesChoices> => {
    const response = await unauthorizedApiClient().get("/api/websettings/get_lang")
    return response.data
}

const getManualUrl = async (token: string): Promise<{ url: string }> => {
    const response = await authorizedApiClient(token).get<{ url: string }>("/api/websettings/get_manual_blob_url/")
    return response.data
}

const AdminService = {
    getListOfActionLogs,
    getActionLogById,
    getListOfOrganizations,
    getMyOrganization,
    getDepartmentsOfOrganizations,
    getUsersOfOrganizations,
    getOrganizationById,
    createOrganization,
    updateOrganization,
    deleteOrganization,
    getListOfDepartments,
    getDepartmentById,
    createDepartment,
    updateDepartment,
    deleteDepartment,
    getCurrentUser,
    saveAgreementWithManual,
    getListOfUsers,
    getUserById,
    createUser,
    createInvitedUser,
    updateUser,
    updateMyProfileData,
    deleteUser,
    changeGroupOfUser,
    getListOfInvitations,
    getInvitationById,
    createInvitation,
    updateInvitation,
    deleteInvitation,
    resendInvitation,
    getInvitationByKey,
    changePassword,
    resetPassword,
    signIn,
    sendTicket,
    getWebSettings,
    getListOfTimezones,
    getLanguagesChoices,
    getManualUrl
}

export default AdminService