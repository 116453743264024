import Context from './Context'
import {JSXElementConstructor} from 'react'

const withContainer = (Component: JSXElementConstructor<any>) => {
    const ChildComponent = (props: any) => {
        return (
            <Context.Consumer>
                {(value) => {
            const { appConfig } = value || {}
            return <Component appConfig={appConfig} {...props} />
        }}
        </Context.Consumer>
    )
    }

    return ChildComponent
}

export default withContainer