import { ReactPlugin} from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { createBrowserHistory } from "history"


export interface IFailTracker {
  organization_id?: string
  department_id?: string,
  error?: any,
  user_id?: number
}

export interface IUploadTracker { 
  analysis_id?: number
  organization_id?: string
  department_id?: string
  count?: number
  user_id?: number
}

export interface IFileTrackerFail extends IFailTracker{
  analysis_id?: number,
  inputs?: string
}

export interface IDownloadPdfTracker {
  analysis_id?: number
  month?: string
  year?: string
  organization_id?: string
  department_id?: string
  user_id?: number
}

export interface IPdfViewerTrackerFail {
  organization_id?: string
  department_id?: string
  user_id?: number
  url?: string,
  error?: string
}

export const reactPlugin = new ReactPlugin()
const browserHistory = createBrowserHistory()

export function createAppInsight(key: string) {
  return new ApplicationInsights({
    config: {
        instrumentationKey: key,
        extensions: [reactPlugin],
        extensionConfig: {
          [reactPlugin.identifier]: { history: browserHistory }
        },
        disableCookiesUsage: true,
        disableAjaxTracking: true,
        disableExceptionTracking: true
    }
})
}