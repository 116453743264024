import { useState, FC } from 'react'
import Context from './Context'
import { IWebSettingsContext } from "./types"


const Provider: FC<{children: any, defaultWebSettings?: IWebSettingsContext["webSettings"]}> = ({
                    children,
                    defaultWebSettings = {}
                }) => {
    const [webSettings, setWebSettings] = useState<IWebSettingsContext["webSettings"]>(defaultWebSettings)
    
    const getWebSettingsValue = (name: string): string => {
        var value = "0"

        try {
            value = webSettings[name as keyof typeof webSettings] as string
        } catch (e) {
            console.warn(e)
        }

        return value
    }

    return (
        <Context.Provider
            value={{
                webSettings,
                setWebSettings,
                getWebSettingsValue
            }}
        >
            {children}
        </Context.Provider>
    )
}

export default Provider